<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmicons.formtitle')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field :label="$t('pmicons.name')" v-model="model.name" toplabel required maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field :label="$t('pmicons.acronym')" v-model="model.acronym" toplabel required maxlength="10"></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field :label="$t('pmicons.route')" v-model="model.route" toplabel required maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-select
								attach="pmiconstypeid"
								:label="this.$t('pmicons.iconstype')"
								:placeholder="this.$t('pmicons.phiconstype')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmiconstype"
								:modelFormMapping="{ pmiconstypeid: 'pmiconstypeid' }"
								:itemsToSelect="iconstypeItemsToSelect"
								itemValue="pmiconstypeid"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmicons.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmicons.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="reloadIcons"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
//import constants from '@/utils/constants';
import { search } from '@/api/common';

export default {
	name: 'pmiconsform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmicons',
			model: 'pmicons'
		};
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		iconstypeItemsToSelect() {
			return [{ pmiconstypeid: this.model.pmiconstypeid }];
		}
	},
	mounted() {},
	updated() {
		if (this.model) {
			if (!this.modelLoaded) {
				this.modelLoaded = true;
			}
		}
	},
	methods: {
		reloadIcons() {
			if (this.$refs.form && this.$refs.form.validate && this.$refs.form.validate()) {
				this.retrieveIcons();
			}
			this.save();
		},
		async retrieveIcons() {
			let body = {
				model: 'vluppmicons',
				filter: {},
				rows: 99
			};
			let { data } = await search(body);
			console.log('After get ALL icons: ', data.data);
			this.$store.commit('setIcons', data.data);
			return data;
		}
	}
};
</script>
